"use client"
import React from 'react'
import MovieSideBarCard from '../Card/MovieSideBarCard'
import { Tab, Tabs } from '@nextui-org/tabs'

export default function TabSideBar({rankMovieDay,rankMovieWeek,rankMovieMonth}) {
  return (
    <Tabs aria-label="Options" color="primary" variant="bordered">
        <Tab key="day" title="Hôm Nay">
            <div className='md:flex md:flex-col gap-3 md:flex-col grid grid-cols-2 md:grid-cols-1 gap-2 auto-rows-max'>
            {
                rankMovieDay.map((movie,index) => {
                return (<MovieSideBarCard type="day" key={movie.id} index={index} movie={movie}/>)
                })
            }
            </div>
        </Tab>
        <Tab key="week" title="Tuần">
            <div className='md:flex md:flex-col gap-3 md:flex-col grid grid-cols-2 md:grid-cols-1 gap-2 auto-rows-max'>
            {
                rankMovieWeek.map((movie,index) => {
                return (<MovieSideBarCard type="week" key={movie.id} index={index} movie={movie}/>)
                })
            }
            </div>
        </Tab>
        <Tab key="month" title="Tháng">
            <div className='md:flex md:flex-col gap-3 md:flex-col grid grid-cols-2 md:grid-cols-1 gap-2 auto-rows-max'>
            {
                rankMovieMonth.map((movie,index) => {
                return (<MovieSideBarCard type="month" key={movie.id} index={index} movie={movie}/>)
                })
            }
            </div>
        </Tab>
    </Tabs>
  )
}